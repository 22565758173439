import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA, isDevMode } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClipboardModule } from 'ngx-clipboard';
import { TooltipModule } from 'ng2-tooltip-directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { TabsModule } from 'ngx-tabset';
import { StickyNavModule } from 'ng2-sticky-nav';
import { AccordionModule } from "ngx-accordion";
import { NgxPaginationModule } from 'ngx-pagination';
import { LightboxModule } from 'ngx-lightbox';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeDemoOneComponent } from './components/pages/home-demo-one/home-demo-one.component';
import { HomeDemoTwoComponent } from './components/pages/home-demo-two/home-demo-two.component';
import { HomeDemoThreeComponent } from './components/pages/home-demo-three/home-demo-three.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { HometwoBannerComponent } from './components/pages/home-demo-two/hometwo-banner/hometwo-banner.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { LocationsPageComponent } from './components/pages/locations-page/locations-page.component';
import { CategoriesPageComponent } from './components/pages/categories-page/categories-page.component';
import { CouponsPageComponent } from './components/pages/coupons-page/coupons-page.component';
import { StoresPageComponent } from './components/pages/stores-page/stores-page.component';
import { StoresDetailsPageComponent } from './components/pages/stores-details-page/stores-details-page.component';
import { DealsPageComponent } from './components/pages/deals-page/deals-page.component';
import { HowItWorksPageComponent } from './components/pages/how-it-works-page/how-it-works-page.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { ProfileAuthenticationPageComponent } from './components/pages/profile-authentication-page/profile-authentication-page.component';
import { BlogGridPageComponent } from './components/pages/blog-grid-page/blog-grid-page.component';
import { BlogRightSidebarPageComponent } from './components/pages/blog-right-sidebar-page/blog-right-sidebar-page.component';
import { BlogDetailsPageComponent } from './components/pages/blog-details-page/blog-details-page.component';
import { GalleryPageComponent } from './components/pages/gallery-page/gallery-page.component';
import { DealsDetailsPageComponent } from './components/pages/deals-details-page/deals-details-page.component';
import { CartPageComponent } from './components/pages/cart-page/cart-page.component';
import { CheckoutPageComponent } from './components/pages/checkout-page/checkout-page.component';
import { ProductsListPageComponent } from './components/pages/products-list-page/products-list-page.component';
import { ProductsDetailsPageComponent } from './components/pages/products-details-page/products-details-page.component';
import { RelatedProductsComponent } from './components/pages/products-details-page/related-products/related-products.component';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './components/shared/shared.module';
import { MaterialModule } from './core/material.module';
import { NotifierModule } from 'angular-notifier';
import { HttpClientModule } from '@angular/common/http';
import { Config } from './core/config/config';
import { CoreModule } from './core/core.module';
import { RegisterComponent } from './components/pages/register/register.component';
import { TermConditionPageComponent } from './components/pages/term-condition-page/term-condition-page.component';
import { PrivacyPolicyPageComponent } from './components/pages/privacy-policy-page/privacy-policy-page.component';
import { ForgetPasswordComponent } from './components/pages/forget-password/forget-password.component';
import { ResetPasswordComponent } from './components/pages/reset-password/reset-password.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { QuickTipPageComponent } from './components/pages/quick-tip-page/quick-tip-page.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeDemoOneComponent,
    HomeDemoTwoComponent,
    HomeDemoThreeComponent,
    ContactPageComponent,
    HometwoBannerComponent,
    FaqPageComponent,
    TermConditionPageComponent,
    PrivacyPolicyPageComponent,
    LocationsPageComponent,
    CategoriesPageComponent,
    CouponsPageComponent,
    StoresPageComponent,
    StoresDetailsPageComponent,
    DealsPageComponent,
    HowItWorksPageComponent,
    ComingSoonPageComponent,
    ProfileAuthenticationPageComponent,
    RegisterComponent,
    BlogGridPageComponent,
    BlogRightSidebarPageComponent,
    BlogDetailsPageComponent,
    GalleryPageComponent,
    DealsDetailsPageComponent,
    CartPageComponent,
    CheckoutPageComponent,
    ProductsListPageComponent,
    ProductsDetailsPageComponent,
    RelatedProductsComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    QuickTipPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ClipboardModule,
    TooltipModule,
    ReactiveFormsModule,
    FormsModule,
    NgxScrollTopModule,
    TabsModule,
    StickyNavModule,
    AccordionModule,
    NgxPaginationModule,
    LightboxModule,
    NotifierModule,
    SharedModule,
    LayoutModule,
    MaterialModule,
    CoreModule,
    
    NotifierModule.withConfig(Config.notifierDefaultOptions),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
