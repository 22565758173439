import { Component, OnInit } from '@angular/core';
import { ResourceService } from 'src/app/services/common/resource.service';
import { StorageService } from 'src/app/services/common/storage.service';
import { UserInfoService } from 'src/app/services/user-info.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    isLoggedIn: any = false;
    showInstallBox = false;
    resource: any = {};
    showFooter = false;

    constructor(private userInfoService: UserInfoService,
        private storageService: StorageService,
        private resourceService: ResourceService) {
        window.addEventListener("beforeinstallprompt", () => setTimeout(() => {
            this.showInstallBox = !!(window as any).beforeInstallPrompt
        }, 0));
    }

    ngOnInit(): void {
        this.loadResoruce();
        this.resourceService.languageSubject.subscribe(response => {
            this.resource = response;
        });
        this.isLoggedIn = this.userInfoService.getLoginFlag();
        this.storageService.loginSubject.subscribe(message => {
            this.isLoggedIn = this.userInfoService.getLoginFlag();
        });

    }

    loadResoruce() {
        this.resourceService.loadResoruce().subscribe(
            response => {
                this.resource = response;
            });
    }

    singleFooterWidget = [
        {
            logo: `assets/img/logo.png`,
            paragraph: `Launching and redeeming coupons made easier!`
        }
    ]

    marxaYear: number = new Date().getFullYear();


    onClickInstall() {
        (window as any).triggerInstallPopup()
    }

  toggleFooter() {
    this.showFooter = !this.showFooter;
  }
  
}